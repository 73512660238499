export default function validateContactForm(values) {
  let errors = {};

  if (!values.fname) {
    errors.fname = 'Le prénom est obligatoire';
  }

  if (!values.lname) {
    errors.lname = 'Le nom de famille est obligatoire';
  }

  if (!values.email) {
    errors.email = "L'adresse e-mail est obligatoire";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "L'adresse e-mail est invalide";
  }

  if (!values.subject) {
    errors.subject = "L'objet est obligatoire";
  } else if (values.subject.length < 5) {
    errors.subject = "L'objet doit contenir au moins 5 caractères";
  }

  if (!values.message) {
    errors.message = 'Le message est obligatoire';
  } else if (values.message.length < 10) {
    errors.message = 'Le message doit contenir au moins 10 caractères';
  }

  return errors;
};
