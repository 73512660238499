//https://upmostly.com/tutorials/using-custom-react-hooks-simplify-forms
import React, { useEffect, useState } from 'react';
import useContactForm from "./useContactForm";
import validateContactForm from './ContactFormValidationRules';
import emailjs from '@emailjs/browser';

const ContactForm = () => {

  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);

  const { values, errors, handleChange, handleSubmit } = useContactForm(submitContactForm, validateContactForm);

  // State to keep track of email sending status
  const [emailStatus, setEmailStatus] = useState(null);

  function submitContactForm() {
    // Create an HTML message that includes all the form details
    var messageHTML = `
      <h1 style="font-size: 24px;">Nouvelle soumission du formulaire de contact</h1>
      <p><strong>Prénom :</strong> ${values.fname}</p>
      <p><strong>Nom de famille :</strong> ${values.lname}</p>
      <p><strong>Email :</strong> ${values.email}</p>
      <p><strong>Objet :</strong> ${values.subject}</p>
      <p><strong>Message :</strong><br/><br/> ${values.message.replace(/\n/g, '<br>')}</p>
    `;

    var template_params = {
      "reply_to": values.email,
      "from_name": `${values.fname} ${values.lname}`,
      "to_name": "contact@mdiatech.com",
      "message_html": messageHTML
    }

    var service_id = "service_yh3lxmf";
    var template_id = "template_MdiaTechContact";
    emailjs.send(service_id, template_id, template_params, "user_b1pFF2VMlM774TyDzP4OY")
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setEmailStatus('success');  // Update state to 'success'
      }, (err) => {
        console.log('FAILED...', err);
        setEmailStatus('error');  // Update state to 'error'
      });
  }

  return (
    <div className="App">
      <div className="site-wrap">

        <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url('${bgImage}')`, backgroundPosition: "50%" }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">

              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">

                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>Nous contacter</h1>
                    <p className="lead mb-5">Nous créons de la valeur ajoutée</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="site-section bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-7 mb-5">

                <form onSubmit={handleSubmit} className="p-5 bg-white" noValidate>
                  <h2 className="h4 text-black mb-5">Formulaire de contact</h2>
                  <div className="row form-group">
                    <div className="col-md-6 mb-3 mb-md-0">
                      <label className="text-black" htmlFor="fname">Prénom*</label>
                      <input type="text" id="fname" name="fname" className={`form-control ${errors.fname && 'is-danger'}`} onChange={handleChange} value={values.fname || ''} required />
                      {errors.fname && (
                        <p className="help is-danger" style={{ color: '#0d0cb5' }}>{errors.fname}</p>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label className="text-black" htmlFor="lname">Nom de famille*</label>
                      <input type="text" id="lname" name="lname" className={`form-control ${errors.lname && 'is-danger'}`} onChange={handleChange} value={values.lname || ''} required />
                      {errors.lname && (
                        <p className="help is-danger" style={{ color: '#0d0cb5' }}>{errors.lname}</p>
                      )}
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="text-black" htmlFor="email">Email*</label>
                      <input type="email" id="email" name="email" className={`form-control ${errors.email && 'is-danger'}`} onChange={handleChange} value={values.email || ''} required />
                      {errors.email && (
                        <p className="help is-danger" style={{ color: '#0d0cb5' }}>{errors.email}</p>
                      )}
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="text-black" htmlFor="subject">Objet*</label>
                      <input type="text" id="subject" name="subject" className={`form-control ${errors.subject && 'is-danger'}`} onChange={handleChange} value={values.subject || ''} required />
                      {errors.subject && (
                        <p className="help is-danger" style={{ color: '#0d0cb5' }}>{errors.subject}</p>
                      )}
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="text-black" htmlFor="message">Message*</label>
                      <textarea name="message" id="message" cols="30" rows="7" className={`form-control ${errors.message && 'is-danger'}`} onChange={handleChange} value={values.message || ''} required />
                      {errors.message && (
                        <p className="help is-danger" style={{ color: '#0d0cb5' }}>{errors.message}</p>
                      )}
                    </div>
                  </div>
                  <label className="text-black" htmlFor="message">* Obligatoire</label>
                  <div className="row form-group">
                    <div className="col-md-12">
                      <input type="submit" value="Envoyer" className="btn btn-primary btn-md text-white" />
                    </div>
                    <div className="col-md-12 mt-3">
                      {emailStatus === 'success' && <span className="text-success" style={{ marginTop: '20px' }}>Message envoyé avec succès !</span>}
                      {emailStatus === 'error' && <span className="text-danger" style={{ marginTop: '20px' }}>Une erreur s'est produite. Veuillez réessayer.</span>}
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-5">

                <div className="p-4 mb-3 bg-white">
                  <p className="mb-0 font-weight-bold">Adresse</p>
                  <p className="mb-4">7, rue de l'Ancienne Mairie -  Boulogne-Billancourt - FRANCE</p>

                  <p className="mb-0 font-weight-bold">Téléphone</p>
                  <p className="mb-4"><a href="tel:+33632957668">+33 6 32 95 76 68</a></p>

                  <p className="mb-0 font-weight-bold">Email</p>
                  <p className="mb-0"><a href="mailto:contact@mdiatech.com">contact@mdiatech.com</a></p>

                </div>

                <div className="p-4 mb-3 bg-white">
                  <h3 className="h5 text-black mb-3">Plus d'information</h3>
                  <p>Notre coeur de métier est le conseil en management des nouvelles technologies de l’information et du digital. Notre principe s’appuie sur une approche collaborative avec nos clients. Nous aidons nos clients à se transformer grâce à des solutions personnalisées, pensées et imaginées ensemble.</p>
                  <p><a href="/about" className="btn btn-primary btn-md text-white">En savoir plus</a></p>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ContactForm;