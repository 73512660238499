import React, { useEffect, useState } from 'react';

const PartnersEMLServicesDigitalSolutions = () => {
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);
  return (
    <div className="App">
      <div className="site-wrap">

        <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url('${bgImage}')`, backgroundPosition: "50%" }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">

              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">

                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>Solutions Digitales EML</h1>
                    <p className="lead mb-5">Répondre à vos besoins est notre priorité</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <section className="site-section border-bottom" style={{paddingBottom: "0px"}}>
          <div className="container">
            <div className="row align-items-stretch">
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" data-aos="fade-up" data-aos-delay="200">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-shopping_cart"></span></div>
                  <div>
                    <h3>Solutions Digitales</h3>
                    <p>Nous utilisons la technologie comme puissant levier de transformation et d’innovation.
                       Nous concilions agilité et complémentarité de nos savoir-faire pour faire du numérique un réel levier de compétitivité et de performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="site-section border-bottom">
          <div className="container">
            <div className="row">
            <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="300">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">01.</div>
                  <h2>Marketing digitale</h2>
                  <p>Nous mettons notre expertise en marketing digital au service des directions commerciales et marketing de nos clients pour les aider à conquérir de nouveaux prospects.</p>
                  <ul>                
                    <li>Prospection et acquisition de nouveaux clients</li>
                    <li>Fidélisation des comptes existants en apportant à chaque problématique sa solution digitale</li>              
                  </ul>
                </div>
              </div>              
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="400">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">02.</div>
                  <h2>Transformation digitale</h2>
                  <p>Digitalisation des processus, personnalisation des expériences clients, innovations de rupture dessinent le nouveau paysage de la vie des entreprises.</p>
                  <p>Pour donner à votre organisation toutes les armes afin de gagner la compétition digitale, il est aujourd’hui nécessaire de placer l’agilité au coeur de son fonctionnement</p>
                  <p>Nos equipes possèdent des connaissances pointues dans les solutions clouds de Microsoft tel que la suite de logiciel Office 365 :</p>
                  <ul>                
                    <li>SharePoint Online</li>
                    <li>Microsoft Teams</li>
                    <li>OneDrive Online</li>                                
                  </ul>    
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="500">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">03.</div>
                  <h2>Applications Web et Mobiles</h2>
                  <p>Nos equipes d’experts sont dédiées aux développements d’applications mobiles et utilisent les dernières technologies telles que React et Node.js</p>              
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="600">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">04.</div>
                  <h2>Application Métiers</h2>
                  <p>Equipes d’experts dédiées à l’accompagnement et développement d’applications métier.</p>
                  <p>Nos experts maîtrisent particulièrement le développement SharePoint, tout comme l'infrastructure SharePoint.</p>
                </div>
              </div> 
            </div>
          </div>
        </section>
        {/*}
        <section className="site-section testimonial-wrap" data-aos="fade">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="text-black h1 site-section-heading text-center">Testimonials</h2>
              </div>
            </div>
          </div>
          <div className="slide-one-item home-slider owl-carousel">
            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>

                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_3.jpg" alt="Person 3" className="w-50 img-fluid mb-3" /></div>
                  <p>John Smith</p>
                </figure>
              </div>
            </div>
            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>
                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_2.jpg" alt="Person 2" className="w-50 img-fluid mb-3" /></div>
                  <p>Christine Aguilar</p>
                </figure>

              </div>
            </div>

            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>
                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_4.jpg" alt="Person 4" className="w-50 img-fluid mb-3" /></div>
                  <p>Robert Spears</p>
                </figure>


              </div>
            </div>

            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>
                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_5.jpg" alt="Person 5" className="w-50 img-fluid mb-3" /></div>
                  <p>Bruce Rogers</p>
                </figure>

              </div>
            </div>

          </div>
        </section>
*/}
      </div>
    </div>
  )
}

export default PartnersEMLServicesDigitalSolutions;