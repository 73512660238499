import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

const Services = () => {

  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);
  return (
    <div className="App">
      <div className="site-wrap">

        <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url('${bgImage}')`, backgroundPosition: "50%" }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">

              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">

                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>Nos Offres</h1>
                    <p className="lead mb-5">Répondre à vos besoins est notre priorité</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <section className="site-section border-bottom">
          <div className="container">
            <div className="row align-items-stretch">
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" style={{paddingBottom: "50px"}}  data-aos="fade-up" data-aos-delay="400">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-windows"></span></div>
                  <div>
                    <h3>Solutions et Infrastructures Microsoft</h3>
                    <p>En tant que spécialistes des solutions Microsoft, notre expertise s'étend bien au-delà des services de base. De la conception et le déploiement d'infrastructures SharePoint à la mise en œuvre de solutions cloud sur Microsoft Azure, nous avons une expérience éprouvée dans la réalisation de projets d'envergure pour des clients de renom tels qu'Orange Business Service et L'Oréal. Notre savoir-faire ne s'arrête pas là; il englobe également des domaines tels que la migration SharePoint, la gouvernance, les solutions multi-locataires et de haute disponibilité à la mise en œuvre de solutions de continuité d'activité. Nous sommes également compétents dans la mise en place de solutions de sécurité et de conformité, ce qui fait de nous un partenaire de choix pour vos besoins en solutions Microsoft.</p>
                    <p><Link to="/services-microsoft-solutions">Découvrir</Link ></p>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" style={{paddingBottom: "50px"}} data-aos="fade-up" data-aos-delay="500">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-group"></span></div>
                  <div>
                    <h3>Collaboration et Productivité</h3>
                    <p>Notre mission est de transformer la manière dont votre entreprise collabore, produit et aborde votre transformation digitale. Nous proposons des solutions sur mesure en communication unifiée grace à Microsoft Teams et SharePoint, en analyse de données avec Power BI, et en automatisation des flux de travail grâce à Power Automate. Nos solutions ont été la pierre angulaire de la transformation numérique de grandes entreprises, y compris dans des secteurs aussi divers et exigeants que la banque et l'assurance. Nous nous appuyons sur des technologies avancées pour créer des solutions qui non seulement répondent aux besoins actuels de votre entreprise, mais la préparent également pour l'avenir.</p>
                    <p><Link to="/services-collaboration-productivity">Découvrir</Link ></p>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" style={{paddingBottom: "50px"}} data-aos="fade-up" data-aos-delay="600">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-shield"></span></div>
                  <div>
                    <h3>DevOps, Sécurité et Conformité</h3>
                    <p>Nous sommes plus qu'un simple fournisseur de services DevOps; nous sommes votre partenaire stratégique pour assurer la transition fluide de vos opérations vers des pratiques DevOps avancées. De l'automatisation avec PowerShell, la création de chaine de déploiement à la mise en place de mesures de sécurité rigoureuses et de conformité, nous avons une expérience éprouvée dans des environnements hautement réglementés. Nous adoptons une approche holistique pour garantir que vos systèmes ne sont pas seulement sécurisés, mais aussi optimisés pour la performance.</p>
                    <p><Link to="/services-devops-security">Découvrir</Link ></p>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" style={{paddingBottom: "50px"}} data-aos="fade-up" data-aos-delay="700">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-laptop"></span></div>
                  <div>
                    <h3>Développement et Gestion de Projet</h3>
                    <p>Notre expertise en développement et gestion de projet est ancrée dans une solide expérience avec les méthodologies Agile, notamment SCRUM et KANBAN. Nous vous accompagnons à chaque étape, du cahier des charges à la livraison, en passant par les phases de test et de déploiement. Que ce soit pour le développement en C# et .NET, ou pour des solutions plus spécifiques comme React et le SharePoint Framework, nous avons les compétences et l'expérience pour mener vos projets à bien. Notre approche est centrée sur la collaboration étroite avec les parties prenantes du projet, ce qui nous permet de livrer des solutions qui répondent précisément aux besoins de votre entreprise.</p>
                    <p><Link to="/services-development-management">Découvrir</Link ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*}
        <section className="site-section border-bottom">
          <div className="container">
            <div className="row">
              {location.pathname == "/services" | location.pathname == "/services-data-strength" &&
                <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up">
                  <div className="p-3 box-with-humber">
                    <div className="number-behind">01.</div>
                    <h2>Web Design</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.</p>
                  </div>
                </div>}
              {location.pathname == "/services" | location.pathname == "/services-data-strength" &&
                <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="100">
                  <div className="p-3 box-with-humber">
                    <div className="number-behind">02.</div>
                    <h2>Web Apps</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.</p>
                  </div>
                </div>}
              {location.pathname == "/services" | location.pathname == "/services-digital-solutions" &&
                <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="200">
                  <div className="p-3 box-with-humber">
                    <div className="number-behind">03.</div>
                    <h2>WordPress</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.</p>
                  </div>
                </div>}
              {location.pathname == "/services" | location.pathname == "/services-digital-solutions" &&
                <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="300">
                  <div className="p-3 box-with-humber">
                    <div className="number-behind">04.</div>
                    <h2>Web Design</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.</p>
                  </div>
                </div>}
              {location.pathname == "/services" | location.pathname == "/services-business-solutions" &&
                <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="400">
                  <div className="p-3 box-with-humber">
                    <div className="number-behind">05.</div>
                    <h2>Web Apps</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.</p>
                  </div>
                </div>}
              {location.pathname == "/services" | location.pathname == "/services-business-solutions" &&
              <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="500">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">06.</div>
                  <h2>WordPress</h2>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.</p>
                </div>
              </div>}
            </div>
          </div>
  </section>*/}
        {/*}
        <section className="site-section testimonial-wrap" data-aos="fade">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="text-black h1 site-section-heading text-center">Testimonials</h2>
              </div>
            </div>
          </div>
          <div className="slide-one-item home-slider owl-carousel">
            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>

                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_3.jpg" alt="Person 3" className="w-50 img-fluid mb-3" /></div>
                  <p>John Smith</p>
                </figure>
              </div>
            </div>
            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>
                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_2.jpg" alt="Person 2" className="w-50 img-fluid mb-3" /></div>
                  <p>Christine Aguilar</p>
                </figure>

              </div>
            </div>

            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>
                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_4.jpg" alt="Person 4" className="w-50 img-fluid mb-3" /></div>
                  <p>Robert Spears</p>
                </figure>

              </div>
            </div>

            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>
                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_5.jpg" alt="Person 5" className="w-50 img-fluid mb-3" /></div>
                  <p>Bruce Rogers</p>
                </figure>

              </div>
            </div>

          </div>
        </section>
*/}
      </div>
    </div>
  )
}

export default Services;