import React from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";

const Footer = () => {
  let location = useLocation();
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              {location.pathname !== "/about" &&
                <div className="col-md-5">
                  <h2 className="footer-heading mb-4">A propos</h2>
                  <p>Notre coeur de métier est le conseil en management des nouvelles technologies de l’information et du digital. Notre principe s’appuie sur une approche collaborative avec nos clients. Nous aidons nos clients à se transformer grâce à des solutions personnalisées, pensées et imaginées ensemble.</p>
                  <p>En somme, notre mission est de vous accompagner dans votre transformation numérique en fournissant des conseils experts et des solutions personnalisées, conçues en collaboration pour répondre à vos besoins uniques.</p>
                </div>
              }
              <div className="col-md-3 ml-auto">
                <h2 className="footer-heading mb-4">Liens rapides</h2>
                <ul className="list-unstyled">
                <li><Link to="/">Accueil</Link ></li>
                  <li><Link to="/about">A propos</Link ></li>
                  <li><Link to="/services">Offres</Link></li>
                  <li><Link to="/client-references">Réfèrences</Link></li>
                  <li><Link to="/partners">Partenaires</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                </ul>
              </div>
              {location.pathname !== "/services" && location.pathname !== "/work" &&
                <div className="col-md-3">
                  <Link to="/services-microsoft-solutions">
                    <h2 className="footer-heading mb-4">Solutions et Infrastructures Microsoft</h2>
                  </Link>
                  <ul className="list-unstyled">
                    <li><HashLink to="/services-microsoft-solutions#m365">Microsoft 365</HashLink></li>
                    <li><HashLink to="/services-microsoft-solutions#microsoftazure">Microsoft Azure</HashLink></li>                    
                    <li><HashLink to="/services-microsoft-solutions#infrastructuresharepoint">Infrastructure SharePoint</HashLink></li>
                    <li><HashLink to="/services-microsoft-solutions#adfs-authentification">ADFS & Authentification</HashLink></li>
                    <li><HashLink to="/services-microsoft-solutions#multitenancy">Solutions multi-locataires</HashLink></li>
                    <li><HashLink to="/services-microsoft-solutions#migration-m365">Migration vers Microsoft 365</HashLink></li>                    
                    <li><HashLink to="/services-microsoft-solutions#migration-sharepoint">Migration SharePoint</HashLink></li>
                    <li><HashLink to="/services-microsoft-solutions#sqlserver">SQL Server & Bases de Données</HashLink></li>
                    <li><HashLink to="/services-microsoft-solutions#hautedisponibilite">Haute Disponibilité</HashLink></li>
                  </ul>
                </div>
              }
              {location.pathname !== "/services" && location.pathname !== "/work" &&
                <div className="col-md-3">
                  <Link to="/services-collaboration-productivity">
                    <h2 className="footer-heading mb-4">Collaboration et Productivité</h2>
                  </Link>
                  <ul className="list-unstyled">
                    <li><HashLink to="/services-collaboration-productivity#solutions-collaboratives">Solutions Collaboratives</HashLink></li>
                    <li><HashLink to="/services-collaboration-productivity#teams-communication">Microsoft Teams & Communication Unifiée</HashLink></li>
                    <li><HashLink to="/services-collaboration-productivity#gouvernance">Gouvernance</HashLink></li>                    
                    <li><HashLink to="/services-collaboration-productivity#power-apps">Power Apps & Power Automate</HashLink></li>
                    {/*
                    <li><HashLink to="/services-collaboration-productivity#power-bi">Power BI & Analytics</HashLink></li>
                    */}
                  </ul>
                </div>
              }
              {location.pathname !== "/services" && location.pathname !== "/work" &&
                <div className="col-md-3">
                  <Link to="/services-devops-security">
                    <h2 className="footer-heading mb-4">DevOps, Sécurité et Conformité</h2>
                  </Link>
                  <ul className="list-unstyled">
                    <li><HashLink to="/services-devops-security#devops-microsoft">DevOps Microsoft</HashLink></li>
                    <li><HashLink to="/services-devops-security#automatisation-powershell">Automatisation avec PowerShell</HashLink></li>
                    <li><HashLink to="/services-devops-security#continuous-integration">Intégration Continue</HashLink></li>
                    <li><HashLink to="/services-devops-security#infrastructure-as-code">Infrastructure en tant que code</HashLink></li>
                    <li><HashLink to="/services-devops-security#security-compliance">Sécurité et Compliance</HashLink></li>
                    <li><HashLink to="/services-devops-security#change-management">Change Management</HashLink></li>

                  </ul>
                </div>
              }
              {location.pathname !== "/services" && location.pathname !== "/work" &&
                <div className="col-md-3">
                  <Link to="/services-development-management">
                    <h2 className="footer-heading mb-4">Développement et Gestion de Projet</h2>
                  </Link>
                  <ul className="list-unstyled">
                  <li><HashLink to="/services-development-management#dev-sharepoint-onprem">Solutions SharePoint On-Premise</HashLink></li>                    
                    <li><HashLink to="/services-development-management#spfx-react">SharePoint Framework avec React</HashLink></li>
                    <li><HashLink to="/services-development-management#dotnet">Développement .NET & Asp.Net</HashLink></li>
                    <li><HashLink to="/services-development-management#methodologies-agile">Méthodologies Agile</HashLink></li>
                    <li><HashLink to="/services-development-management#alm">Practiques ALM</HashLink></li>
                  </ul>
                </div>
              }
            </div>
          </div>
          {location.pathname !== "/about" && location.pathname !== "/contact" &&
            <div className="col-md-3"><Link className="btn btn-primary btn-md" to="/contact">Contactez-nous</Link></div>
          }
          {/*}
          <div className="col-md-3">
            <h2 className="footer-heading mb-4">Subscribe Newsletter</h2>
            <form action="#" method="post">
              <div className="input-group mb-3">
                <input type="text" className="form-control border-secondary text-white bg-transparent" placeholder="Enter Email" aria-label="Enter Email" aria-describedby="button-addon2"/>
                <div className="input-group-append">
                  <button className="btn btn-primary text-white" type="button" id="button-addon2">Send</button>
                </div>
              </div>
            </form>
          </div>
          */}
        </div>
        <div className="row pt-5 mt-5 text-center">
          <div className="col-md-12">
            <div className="border-top pt-5">
              <p>
                Copyright MDiaTech &copy; {new Date().getFullYear()} Tous droits réservés
                {/*| Cette template a été créé avec <i className="icon-heart" aria-hidden="true"></i> par <a href="https://colorlib.com" target="_blank" >Colorlib</a> et convertie en une application React par Mdiatech */}
              </p>
            </div>
          </div>

        </div>
      </div>
    </footer>
  )
}

export default Footer;