import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {

  const IconButtonStyles = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 1000
  };

  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled up to given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <div className="scroll-to-top" style={IconButtonStyles}>
      {isVisible && (
          <svg width="48" height="48" viewBox="0 0 1024 1024" onClick={scrollToTop}>
            <path d="M733.714 438.286c0 9.714-3.429 18.857-10.286 25.714l-52 52c-6.857 6.857-16 10.286-25.714 10.286s-18.857-3.429-25.714-10.286l-108-108v286.857c0 20-16.571 36.571-36.571 36.571h-73.143c-20 0-36.571-16.571-36.571-36.571v-286.857l-108 108c-6.857 6.857-16 10.857-25.714 10.857s-18.857-4-25.714-10.857l-52-52c-6.857-6.857-10.286-16-10.286-25.714s3.429-18.857 10.286-25.714l258.857-258.857c6.857-6.857 16-10.286 25.714-10.286s18.857 3.429 25.714 10.286l258.857 258.857c6.857 6.857 10.286 16 10.286 25.714zM877.714 438.857c0-242.286-196.571-438.857-438.857-438.857s-438.857 196.571-438.857 438.857 196.571 438.857 438.857 438.857 438.857-196.571 438.857-438.857z"></path>
          </svg>
      )}
    </div>
  );
};

export default ScrollToTopButton;