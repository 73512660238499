import React, { useEffect, useState } from 'react';

const ServicesCollaborationProductivity = () => {
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      let elem = document.getElementById(window.location.hash.slice(1));
      if (elem) {
        setTimeout(() => {
          window.scrollTo({
            top: elem.getBoundingClientRect().top + window.pageYOffset - 150, // 100px offset
            behavior: 'smooth'
          });
        }, 0);
      }
    }
  }, []);

  return (
    <div className="App">
      <div className="site-wrap">
        <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url('${bgImage}')`, backgroundPosition: "50%" }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">
                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>Collaboration et Productivité</h1>
                    <p className="lead mb-5">Transformez la collaboration en entreprise avec des solutions intelligentes pour la communication.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="site-section border-bottom" style={{ paddingBottom: "0px" }}>
          <div className="container">
            <div className="row align-items-stretch">
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" data-aos="fade-up" data-aos-delay="100">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-question_answer"></span></div>
                  <div>
                    <h3>Collaboration et Productivité</h3>
                    <p>Notre mission est de transformer la manière dont votre entreprise collabore et produit. Nous proposons des solutions sur mesure en communication unifiée et en automatisation des flux de travail grâce à Power Apps et Power Automate.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="site-section border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="200" id="solutions-collaboratives">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">01.</div>
                  <h2>Solutions Collaboratives</h2>
                  <p>Nous offrons des solutions collaboratives qui facilitent la communication et le partage d'informations au sein de votre organisation.</p>
                  <p>En utilisant des plateformes comme SharePoint et Microsoft Teams, nous créons un espace de travail numérique qui encourage la collaboration et augmente la productivité.</p>
                  <p>Que ce soit pour la gestion de projets, le partage de documents ou la communication interne, nos solutions sont conçues pour répondre à vos besoins spécifiques.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="300" id="teams-communication">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">02.</div>
                  <h2>Microsoft Teams & Communication Unifiée</h2>
                  <p>Microsoft Teams est au cœur de notre offre de communication unifiée.</p>
                  <p>Nous configurons et personnalisons Teams pour répondre aux besoins spécifiques de votre entreprise, permettant ainsi une collaboration en temps réel, des réunions en ligne et une intégration transparente avec d'autres outils Microsoft 365.</p>
                  <p>De la mise en place de canaux de communication dédiés à l'intégration de bots d'automatisation, nous rendons Teams plus puissant et adapté à votre environnement de travail.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="400" id="gouvernance">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">03.</div>
                  <h2>Gouvernance Microsoft 365</h2>
                  <p>La gouvernance Microsoft 365 est un élément clé pour garantir que votre organisation utilise efficacement les outils et services fournis par Microsoft 365. Elle englobe des aspects tels que la gestion des identités, la sécurité des données et la conformité réglementaire.</p>
                  <p>Un plan de gouvernance solide permet de définir des rôles et des responsabilités clairs, d'établir des politiques de sécurité et de gestion des données, et de garantir que les utilisateurs sont formés et conscients des meilleures pratiques.</p>
                  <p>En investissant dans une gouvernance efficace, les organisations peuvent réduire les risques, améliorer la productivité et optimiser les coûts. Cela est particulièrement important dans un environnement en constante évolution, où les besoins des utilisateurs et les exigences réglementaires peuvent changer rapidement.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="500" id="power-apps">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">04.</div>
                  <h2>Power Apps & Power Automate</h2>
                  <p>L'automatisation des processus métier et la personnalisation des applications sont cruciales pour une transformation numérique réussie.</p>
                  <p>Nous utilisons Power Apps et Power Automate pour créer des solutions sur mesure qui sont non seulement puissantes mais aussi faciles à maintenir pour les équipes métiers.</p>
                </div>
              </div>
              {/*<div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="600" id="power-bi">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">05.</div>
                  <h2>Power BI & Analytics</h2>
                  <p>L'analyse de données n'est plus un luxe; c'est une nécessité dans le monde des affaires d'aujourd'hui.</p>
                  <p>Avec Power BI, nous transformons vos données brutes en visualisations interactives et en rapports faciles à comprendre, vous permettant ainsi de prendre des décisions éclairées.</p>
                </div>
              </div>
              */}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ServicesCollaborationProductivity;
