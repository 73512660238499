import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import logo from '../assets/Logo/Logo_EML.png'

const Partners = () => {
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);

  return (
    <div className="App">
      <div className="site-wrap">

        <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url('${bgImage}')`, backgroundPosition: "50%" }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">

              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">

                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>EML Consulting</h1>
                    <p className="lead mb-5">L'excellence à votre service, grâce à notre partenariat avec EML Consulting</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <section style={{ display: 'flex', justifyContent: 'center', paddingBottom: 0 }} className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-4" style={{ paddingBottom: '40px' }}>
                <div className="text-left pb-1 border-primary mb-4">
                  <h2 className="text-primary">A propos de notre partenaire</h2>
                </div>
              </div>
              <div className="col-md-9 col-lg-9 d-flex align-items-start" data-aos="zoom-in" data-aos-delay="300">
                <div style={{ marginLeft: '100px', marginRight: '100px' }}>
                  <Link to="https://eml-consulting.fr/">
                    <img src={logo} style={{ height: 100 }} alt="EML Logo" />
                  </Link>
                </div>
                <div className="p-3 box-with-humber">
                  <h2>EML Consulting</h2>
                  <p>L’entreprise a été fondée en 2015 par plusieurs experts issus de cabinet de conseils reconnus sur le marché, sur l’accompagnement ainsi que la mise en place de projets innovants et stratégiques.</p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="site-section border-bottom">
          <div className="container">
            <div className="row align-items-stretch">
              <div className="col-12 mb-4">
                <div className="text-left pb-1 border-primary mb-4">
                  <h2 className="text-primary">Services proposés</h2>
                </div>
              </div>
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" style={{ paddingBottom: "50px" }} data-aos="fade-up" data-aos-delay="100">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-laptop2"></span></div>
                  <div>
                    <h3>Solutions Data</h3>
                    <p>Les technologies et compétences à déployer pour obtenir un maximum de valeur sont multiples et adaptés au besoin Métiers.</p>
                    <p><Link to="/partners-eml-services-data-strength">Découvrir</Link ></p>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" style={{ paddingBottom: "50px" }} data-aos="fade-up" data-aos-delay="200">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-shopping_cart"></span></div>
                  <div>
                    <h3>Solutions Digitales</h3>
                    <p>Nous utilisons la technologie comme puissant levier de transformation et d’innovation.</p>
                    <p>Nous concilions agilité et complémentarité de nos savoir-faire pour faire du numérique un réel levier de compétitivité et de performance.</p>
                    <p><Link to="/partners-eml-services-digital-solutions">Découvrir</Link ></p>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" data-aos="fade-up" data-aos-delay="300">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-question_answer"></span></div>
                  <div>
                    <h3>Solutions Métiers Bancaires</h3>
                    <p>Nous répondons aux demandes des entreprises sur les principaux domaines de gestion, intéropérable et basées sur dernières technologies.</p>
                    <p><Link to="/partners-eml-services-business-solutions">Découvrir</Link ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*}
        <section className="site-section border-bottom">
          <div className="container">
            <div className="row">
              {location.pathname == "/services" | location.pathname == "/services-data-strength" &&
                <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up">
                  <div className="p-3 box-with-humber">
                    <div className="number-behind">01.</div>
                    <h2>Web Design</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.</p>
                  </div>
                </div>}
              {location.pathname == "/services" | location.pathname == "/services-data-strength" &&
                <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="100">
                  <div className="p-3 box-with-humber">
                    <div className="number-behind">02.</div>
                    <h2>Web Apps</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.</p>
                  </div>
                </div>}
              {location.pathname == "/services" | location.pathname == "/services-digital-solutions" &&
                <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="200">
                  <div className="p-3 box-with-humber">
                    <div className="number-behind">03.</div>
                    <h2>WordPress</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.</p>
                  </div>
                </div>}
              {location.pathname == "/services" | location.pathname == "/services-digital-solutions" &&
                <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="300">
                  <div className="p-3 box-with-humber">
                    <div className="number-behind">04.</div>
                    <h2>Web Design</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.</p>
                  </div>
                </div>}
              {location.pathname == "/services" | location.pathname == "/services-business-solutions" &&
                <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="400">
                  <div className="p-3 box-with-humber">
                    <div className="number-behind">05.</div>
                    <h2>Web Apps</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.</p>
                  </div>
                </div>}
              {location.pathname == "/services" | location.pathname == "/services-business-solutions" &&
              <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="500">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">06.</div>
                  <h2>WordPress</h2>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et praesentium eos nulla qui commodi consectetur beatae fugiat. Veniam iste rerum perferendis.</p>
                </div>
              </div>}
            </div>
          </div>
  </section>*/}
        {/*}
        <section className="site-section testimonial-wrap" data-aos="fade">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="text-black h1 site-section-heading text-center">Testimonials</h2>
              </div>
            </div>
          </div>
          <div className="slide-one-item home-slider owl-carousel">
            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>

                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_3.jpg" alt="Person 3" className="w-50 img-fluid mb-3" /></div>
                  <p>John Smith</p>
                </figure>
              </div>
            </div>
            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>
                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_2.jpg" alt="Person 2" className="w-50 img-fluid mb-3" /></div>
                  <p>Christine Aguilar</p>
                </figure>

              </div>
            </div>

            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>
                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_4.jpg" alt="Person 4" className="w-50 img-fluid mb-3" /></div>
                  <p>Robert Spears</p>
                </figure>


              </div>
            </div>

            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>
                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_5.jpg" alt="Person 5" className="w-50 img-fluid mb-3" /></div>
                  <p>Bruce Rogers</p>
                </figure>

              </div>
            </div>

          </div>
        </section>
*/}
        <a href="/contact" className="bg-primary py-5 d-block">
          <div className="container">
            <div className="row justify-content-center">
              <div style={{ textAlign: "center" }} className="col-md10"><h2 className="text-white">Contactez-nous &amp; travaillons ensemble</h2></div>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Partners;