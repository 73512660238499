import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

const About = () => {
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);

  return (
    <div className="App">
      <div className="site-wrap">

        <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url('${bgImage}')`, backgroundPosition: "50%" }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">

              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">

                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>A propos</h1>
                    <p className="lead mb-5">Nous existons à travers vous</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <section style={{ display: 'flex', justifyContent: 'center', paddingBottom: 0 }} className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-4">
                <div className="text-left pb-1 border-primary mb-4">
                  <h2 className="text-primary">Qui sommes-nous?</h2>
                </div>
              </div>
              <div className="col-md-9 col-lg-9" data-aos="fade-up" data-aos-delay="300">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">01.</div>
                  <h2>A propos</h2>
                  <p>En tant qu'experts en solutions Microsoft, notre équipe se démarque par sa polyvalence, intervenant dans des domaines aussi variés que les télécommunications, la finance, l'assurance et les cosmétiques. Notre force réside dans notre approche pragmatique pour surmonter les défis techniques et diriger des équipes de projet avec efficacité. Dotés d'une grande autonomie, nous maîtrisons l'art de la planification, de l'organisation et de la gestion multitâche. Au-delà de notre savoir-faire technique, nous mettons un point d'honneur à communiquer efficacement dans un contexte interculturel, avec une maîtrise courante de l'anglais. Ce qui fait notre singularité, c'est notre focalisation sur l'aspect humain des projets. Nous valorisons la création de relations durables et positives avec tous les membres de l'équipe et les parties prenantes.</p>
                </div>
              </div>
              <div className="col-md-9 col-lg-9" data-aos="fade-up" data-aos-delay="400">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">02.</div>
                  <h2>Chiffres clés</h2>
                  <p>Nous avons eu le privilège de collaborer avec 10 clients exceptionnels, venant de divers secteurs d'activité. Ces partenariats ont été l'occasion de mener à bien des projets innovants, de renforcer notre expertise et de créer des relations durables avec nos clients. Chaque projet a représenté une opportunité unique d'apprentissage et de croissance, et nous sommes fiers de l'impact positif que nous avons pu générer.</p>
                </div>
              </div>
              <div className="col-md-9 col-lg-9" data-aos="fade-up" data-aos-delay="500">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">03.</div>
                  <h2>Domaines d'Expertise</h2>
                  <p>Nous avons une vaste expérience dans plusieurs secteurs d'activité, notamment les télécommunications, la finance, l'assurance et les cosmétiques. Cette diversité nous permet d'apporter une perspective unique et des solutions innovantes à chaque projet. Notre expertise sectorielle nous permet de comprendre les défis spécifiques auxquels nos clients sont confrontés et de fournir des solutions sur mesure qui répondent à leurs besoins spécifiques.</p>
                </div>
              </div>
              <div className="col-md-9 col-lg-9" data-aos="fade-up" data-aos-delay="600">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">04.</div>
                  <h2>Technologies Utilisées</h2>
                  <p>Nous utilisons une gamme diversifiée de technologies pour répondre aux besoins spécifiques de chaque projet. Du développement et infrastructure SharePoint On-Premise aux solutions Microsoft 365 et Azure, notre expertise couvre un large éventail de solutions pour garantir le succès de votre transformation digitale.</p>
                </div>
              </div>
              <div className="col-md-9 col-lg-9" data-aos="fade-up" data-aos-delay="700">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">05.</div>
                  <h2>Nos Valeurs</h2>
                  <p>Nos valeurs fondamentales guident chaque aspect de notre entreprise, de la manière dont nous interagissons avec nos clients et collaborateurs à la façon dont nous abordons nos projets. Nous croyons en l'intégrité, le respect mutuel, et l'innovation continue. Ces principes nous permettent de fournir des solutions de haute qualité et de construire des relations durables avec toutes nos parties prenantes.</p>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section style={{ display: 'flex', justifyContent: 'center', paddingTop: 40 }} className="site-section">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-12 order-md-1" data-aos="fade">

                <div className="row">
                  <div className="col-12 mb-4">
                    <div className="text-left pb-1 border-primary mb-4">
                      <h2 className="text-primary">Nos Offres</h2>
                    </div>
                  </div>

                  <div className="col-sm-4" data-aos="flip-left" data-aos-delay="800">
                    <div className="unit-4">
                      <div className="unit-4-icon mr-4 mb-3"><span className="text-secondary icon-windows"></span></div>
                      <div>
                        <h3>Solutions et Infrastructures Microsoft</h3>
                        <p>Spécialiste en solutions Microsoft, nous offrons une expertise complète allant de la conception d'infrastructures SharePoint à la mise en œuvre de solutions cloud sur Microsoft Azure.</p>
                        <p className="mb-0"><Link to="/services-microsoft-solutions">Découvrir</Link ></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4" data-aos="flip-left" data-aos-delay="1000">
                    <div className="unit-4">
                      <div className="unit-4-icon mr-4 mb-3"><span className="text-secondary icon-group"></span></div>
                      <div>
                        <h3>Collaboration et Productivité</h3>
                        <p>Optimisez la collaboration et la productivité au sein de votre entreprise grâce à nos solutions sur mesure en communication unifiée et d'automatisation des flux de travail.</p>
                        <p className="mb-0"><Link to="/services-collaboration-productivity">Découvrir</Link ></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4" data-aos="flip-left" data-aos-delay="1200">
                    <div className="unit-4">
                      <div className="unit-4-icon mr-4 mb-3"><span className="text-secondary icon-shield"></span></div>
                      <div>
                        <h3>DevOps, Sécurité et Conformité</h3>
                        <p>Votre partenaire de confiance pour intégrer les meilleures pratiques DevOps, garantir la sécurité de vos systèmes et assurer la conformité réglementaire de vos opérations.</p>
                        <p className="mb-0"><Link to="/services-devops-security">Découvrir</Link ></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4" data-aos="flip-left" data-aos-delay="1100">
                    <div className="unit-4">
                      <div className="unit-4-icon mr-4 mb-3"><span className="text-secondary icon-laptop"></span></div>
                      <div>
                        <h3>Développement et Gestion de Projet</h3>
                        <p>Expérimentés dans les méthodologies Agile, nous vous accompagnons dans le développement de solutions logicielles robustes et la gestion efficace de vos projets.</p>
                        <p className="mb-0"><Link to="/services-development-management">Découvrir</Link ></p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        {/*
        <div className="site-section border-bottom">
          <div className="container">
            <div className="row justify-content-center mb-5">
              <div className="col-md-7 text-center border-primary">
                <h2 className="font-weight-light text-primary" data-aos="fade">Our Team</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="100">
                <div className="person">
                  <img src="images/person_2.jpg" alt="Person 2" className="img-fluid rounded-circle w-50 mb-5"/>
                  <h3>Christine Rooster</h3>
                  <p className="position text-muted">Co-Founder, President</p>
                  <p className="mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi at consequatur unde molestiae quidem provident voluptatum deleniti quo iste error eos est praesentium distinctio cupiditate tempore suscipit inventore deserunt tenetur.</p>
                  <ul className="ul-social-circle">
                    <li><a href="#"><span className="icon-facebook"></span></a></li>
                    <li><a href="#"><span className="icon-twitter"></span></a></li>
                    <li><a href="#"><span className="icon-linkedin"></span></a></li>
                    <li><a href="#"><span className="icon-instagram"></span></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="200">
                <div className="person">
                  <img src="images/person_3.jpg" alt="Person 3" className="img-fluid rounded-circle w-50 mb-5"/>
                  <h3>Brandon Sharp</h3>
                  <p className="position text-muted">Co-Founder, COO</p>
                  <p className="mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi at consequatur unde molestiae quidem provident voluptatum deleniti quo iste error eos est praesentium distinctio cupiditate tempore suscipit inventore deserunt tenetur.</p>
                  <ul className="ul-social-circle">
                    <li><a href="#"><span className="icon-facebook"></span></a></li>
                    <li><a href="#"><span className="icon-twitter"></span></a></li>
                    <li><a href="#"><span className="icon-linkedin"></span></a></li>
                    <li><a href="#"><span className="icon-instagram"></span></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-5 mb-lg-0" data-aos="fade" data-aos-delay="300">
                <div className="person">
                  <img src="images/person_4.jpg" alt="Person 4" className="img-fluid rounded-circle w-50 mb-5"/>
                  <h3>Connor Hodson</h3>
                  <p className="position text-muted">Marketing</p>
                  <p className="mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi at consequatur unde molestiae quidem provident voluptatum deleniti quo iste error eos est praesentium distinctio cupiditate tempore suscipit inventore deserunt tenetur.</p>
                  <ul className="ul-social-circle">
                    <li><a href="#"><span className="icon-facebook"></span></a></li>
                    <li><a href="#"><span className="icon-twitter"></span></a></li>
                    <li><a href="#"><span className="icon-linkedin"></span></a></li>
                    <li><a href="#"><span className="icon-instagram"></span></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <section className="site-section testimonial-wrap" data-aos="fade">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="text-black h1 site-section-heading text-center">Testimonials</h2>
              </div>
            </div>
          </div>
          <div className="slide-one-item home-slider owl-carousel">
              <div>
                <div className="testimonial">
                  
                  <blockquote className="mb-5">
                    <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                  </blockquote>

                  <figure className="mb-4 d-flex align-items-center justify-content-center">
                    <div><img src="images/person_3.jpg" alt="Person 3" className="w-50 img-fluid mb-3"/></div>
                    <p>John Smith</p>
                  </figure>
                </div>
              </div>
              <div>
                <div className="testimonial">

                  <blockquote className="mb-5">
                    <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                  </blockquote>
                  <figure className="mb-4 d-flex align-items-center justify-content-center">
                    <div><img src="images/person_2.jpg" alt="Person 2" className="w-50 img-fluid mb-3"/></div>
                    <p>Christine Aguilar</p>
                  </figure>
                  
                </div>
              </div>

              <div>
                <div className="testimonial">

                  <blockquote className="mb-5">
                    <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                  </blockquote>
                  <figure className="mb-4 d-flex align-items-center justify-content-center">
                    <div><img src="images/person_4.jpg" alt="Person 4" className="w-50 img-fluid mb-3"/></div>
                    <p>Robert Spears</p>
                  </figure>

                  
                </div>
              </div>

              <div>
                <div className="testimonial">

                  <blockquote className="mb-5">
                    <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                  </blockquote>
                  <figure className="mb-4 d-flex align-items-center justify-content-center">
                    <div><img src="images/person_5.jpg" alt="Person 5" className="w-50 img-fluid mb-3"/></div>
                    <p>Bruce Rogers</p>
                  </figure>

                </div>
              </div>

            </div>
        </section>
        */}
        <a href="/contact" className="bg-primary py-5 d-block">
          <div className="container">
            <div className="row justify-content-center">
              <div style={{ textAlign: "center" }} className="col-md10"><h2 className="text-white">Contactez-nous &amp; travaillons ensemble</h2></div>
            </div>
          </div>
        </a>

      </div>
    </div>
  )
}

export default About;