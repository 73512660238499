import React, { useEffect, useState } from 'react';

const PartnersEMLServicesBusinessSolutions = () => {
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);
  return (
    <div className="App">
      <div className="site-wrap">

        <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url('${bgImage}')`, backgroundPosition: "50%" }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">

              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">

                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>Solutions Métiers Bancaires EML</h1>
                    <p className="lead mb-5">Répondre à vos besoins est notre priorité</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <section className="site-section border-bottom" style={{paddingBottom: "0px"}}>
          <div className="container">
            <div className="row align-items-stretch">
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" data-aos="fade-up" data-aos-delay="200">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-question_answer"></span></div>
                  <div>
                    <h3>Solutions Métiers Bancaires</h3>
                    {/*<p>Nous répondons aux demandes des entreprises sur les principaux domaines de gestion, intéropérables et basées sur les dernières technologies.</p>*/}
                    <p>Les mécanismes de blanchiment d'argent devenant chaque jour plus complexes, la mise en œuvre de bonnes stratégies de lutte contre le blanchiment d'argent devient l'une des principales priorités de toute institution financière. Cela nécessite une approche holistique et un système en place qui utilise des analyses et des visualisations de données avancées ainsi que divers outils externes (Actimize, Mantas, etc.) pour surveiller méticuleusement les données transactionnelles et déclencher des signaux d'alarme pertinents.</p>
                    <p>De nos jours, la lutte contre l’anti-blanchiment (AML) au sein des institutions financières fait partie intégrante de leur cœur de métier. Les flux de données financiers étant de plus en plus complexes, le choix de mise en place d’une stratégie efficace est devenu une TOP priorités institutions financières. Cela requiert une approche métier et techniques afin de s’appuyer sur des systèmes experts dans ce domaine dans les tâches de monitoring, détections et traitements des alertes.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="site-section border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="300">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">01.</div>
                  <h2>AML & Regulatory Compliance</h2>
                  <p>En constante transformation, les institutions financières ont toujours fait l’objet de tentatives d’attaques et de fraudes par des criminels à des fins de profits financiers, en se basant sur des activités illicites dans les systèmes financiers.</p>
                  <p>Avec l'accélération des transformations, les institutions financières (IF) ont toujours été un aimant pour les criminels qui tentent d'introduire dans le système financier l'argent provenant d'activités illicites. Le blanchiment d'argent et le financement du terrorisme, outre qu'ils menacent le secteur financier et la stabilité extérieure d'un pays, représentent un risque considérable pour la réputation et l'argent des institutions financières.</p>
                  <p>Le respect des exigences réglementaires implique plusieurs domaines clés, notamment la sélection des clients, la connaissance du client (KYC) et la diligence raisonnable du client (CDD), la surveillance des transactions et des fraudes, le signalement des activités suspectes (SAR) et le signalement des transactions en devises (CTR).</p>
                  <p>Avec une équipe d'experts aussi bien métier que technique, EML Consulting peut fournir des solutions de bout en bout permettant à une institution financière de se conformer aux diverses réglementations en matière de lutte contre le blanchiment d'argent, dont les suivantes :</p>
                  <div style={{textAlign: "center", paddingTop: 30, paddingBottom: 30}} className="h-100">
                      <img src="images/solutions/banque/AML.png" alt="AML Visual" className="img-feature img-fluid"/>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="400">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">02.</div>
                  <h2>Audit SWIFT CSP</h2>
                  <p>Équipe d'experts chargée de fournir le programme de sécurité des clients (CSP) de SWIFT. Il aide les institutions financières à s'assurer que leurs défenses contre les cyberattaques sont à jour et efficaces, afin de protéger l'intégrité du réseau financier au sens large. Les utilisateurs comparent les mesures de sécurité qu'ils ont mises en œuvre avec celles détaillées dans le cadre des contrôles de sécurité des clients (CSCF), avant d'attester chaque année de leur niveau de conformité.</p>
                  <p>Le secteur des services financiers est mondial, tout comme les défis de la cybersécurité. La Society For Worldwide Interbank Financial Telecommunication (SWIFT), une infrastructure de messagerie financière qui relie les banques du monde entier, est souvent vulnérable aux fraudes de paiement. Pour gérer le risque de sécurité, SWIFT a lancé son programme de sécurité des clients (Customer Security Program - CSP) afin d'offrir un forum de collaboration à l'échelle du secteur contre les menaces croissantes de cyberattaques et de contribuer à renforcer et à préserver la sécurité de l'écosystème au sens large.</p>
                  <p>Chez EML, nous combinons des années d'expérience en cybersécurité et en audit dans l'industrie des paiements avec une connaissance approfondie des projets réglementaires dans le secteur financier. Ainsi, nous pouvons vous soutenir dans votre évaluation SWIFT en tant qu'auditeur externe et indépendant. Nous pouvons adapter notre approche en fonction de vos besoins. Vous pouvez choisir l'un des quatre services suivants ou une combinaison de ceux-ci.</p>
                  <p>Ci-dessous les services clés proposé par EML Consulting : </p>
                  <div style={{textAlign: "center", paddingTop: 30, paddingBottom: 30}} className="h-100">
                      <img src="images/solutions/banque/Swift.png" alt="Swift Visual" className="img-feature img-fluid"/>
                  </div>                 
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*<section className="site-section border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">01.</div>
                  <h2>Transfert de CRM</h2>
                  <p>Approche personnelle a l'intention d'exclure tout problème possible pendant la transition CRM et assurer l’assistance par le biais du processus de migration.</p>
                  <ul>
                    <li>Clarification de toutes les données nécessaires au transfert de CRM</li>
                    <li>Enquête et analyse des exigences de migration de données</li>
                    <li>Gestion de la migration complète au CRM</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="200">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">02.</div>
                  <h2>Ressources humaines</h2>
                  <p>Prestataire extérieur, le Consultant RH intervient, dans une entreprise, en qualité de conseil. Il optimise l'organisation de l'entreprise, analyse diverses problématiques RH, trouve des solutions et les met en œuvre, que ce soit dans les relations sociales, du management, du recrutement</p>
                </div>
              </div>              
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="100">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">03.</div>
                  <h2>Supply Chain Management (SCM)</h2>
                  <p>Gestion des flux entre les diffèrents acteurs de la chaîne logistique en amont et en aval, des fournisseurs jusqu’aux clients.</p>
                  <p>Piloter l’ensemble des flux logistiques suite à l’évaluation et la planification de la demande. Gestion de l’ensemble des approvisionnements, contrôle et optimisation en temps réel du niveau des stocks, des composants comme des produits finis (ou semi-finis), l’état des commandes et le plan de production. Supervision de la distribution.</p>
                  <p>Réalisation de son programme et réadaptation en fonction des imprévus et de l’environnement externe. Adaptation du plan de production pour optimiser le ratio charge/capacité, et accroît la flexibilité des unités de production pour absorber les surcharges ponctuelles ou saisonnières.</p>
                  <p>Management de l’ensemble des flux physiques internes et notamment les approvisionnements bords de ligne. Définition du schéma directeur transport, négocié avec les prestataires, définition des incoterms et supervision des problématiques douanières. Externalisation de certaines activités (Production, Stockage, Manutention, Transport) afin d’abaisser les coûts logistiques et les délais tout en relevant la productivité et en garantissant une fiabilité accrue à tous les niveaux.</p>
                  <p>Véritable garant de la qualité de service rendue au client, nos experts sont systématiquement challengés sur l’optimisation de la performance de son périmètre et pilotent les chantiers d’amélioration associés. Ils sont également chargés de déployer sur le terrain la politique du Groupe et les réorganisations résultantes. Ils peuvent gèrer, dans certaines structures, le Service Administration des Ventes.</p>
                </div>
              </div>
            </div>
          </div>
  </section>*/}
        {/*}
        <section className="site-section testimonial-wrap" data-aos="fade">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="text-black h1 site-section-heading text-center">Testimonials</h2>
              </div>
            </div>
          </div>
          <div className="slide-one-item home-slider owl-carousel">
            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>

                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_3.jpg" alt="Person 3" className="w-50 img-fluid mb-3" /></div>
                  <p>John Smith</p>
                </figure>
              </div>
            </div>
            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>
                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_2.jpg" alt="Person 2" className="w-50 img-fluid mb-3" /></div>
                  <p>Christine Aguilar</p>
                </figure>

              </div>
            </div>

            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>
                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_4.jpg" alt="Person 4" className="w-50 img-fluid mb-3" /></div>
                  <p>Robert Spears</p>
                </figure>


              </div>
            </div>

            <div>
              <div className="testimonial">

                <blockquote className="mb-5">
                  <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                </blockquote>
                <figure className="mb-4 d-flex align-items-center justify-content-center">
                  <div><img src="images/person_5.jpg" alt="Person 5" className="w-50 img-fluid mb-3" /></div>
                  <p>Bruce Rogers</p>
                </figure>

              </div>
            </div>

          </div>
        </section>
*/}
      </div>
    </div>
  )
}

export default PartnersEMLServicesBusinessSolutions;