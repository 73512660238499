import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const ClientReferences = () => {
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);  
  return (
    <div className="App">
      <div className="site-wrap">

        <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url('${bgImage}')`, backgroundPosition: "50%" }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">

              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">

                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>Nos Réfèrences Clients</h1>
                    <p className="lead mb-5">Ils nous ont fait confiance dans l’accompagnement ou la mise en œuvre de leurs projets</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <section className="site-section">

          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4 d-flex align-items-center justify-content-center">
                <a href="https://www.axa.fr/" className="media-1" target="_blank" rel="noopener noreferrer">
                  <img src="images/clients/Axa.png" alt="Axa Logo" className="img-fluid" />
                  <div className="media-1-content">
                    <h2>Axa</h2>
                    <span className="category">SharePoint Development</span>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4 mb-4 d-flex align-items-center justify-content-center">
                <a href="https://www.oddo-bhf.com/fr" className="media-1" target="_blank" rel="noopener noreferrer">
                  <img src="images/clients/oddo.png" alt="Oddo Logo" className="img-fluid" />
                  <div className="media-1-content">
                    <h2>Oddo &amp; Cie</h2>
                    <span className="category">Maintenance Corrective SharePoint</span>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4 mb-4 d-flex align-items-center justify-content-center">
                <a href="https://am.oddo-bhf.com/" className="media-1" target="_blank" rel="noopener noreferrer">
                  <img src="images/clients/Oddo BHF Management.png" alt="Oddo BHF Management Logo" className="img-fluid" />
                  <div className="media-1-content">
                    <h2>Oddo BHF Management</h2>
                    <span className="category">Développement SharePoint</span>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4 mb-4 d-flex align-items-center justify-content-center">
                <a href="https://www.ca-ifcam.fr/" className="media-1" target="_blank" rel="noopener noreferrer">
                  <img src="images/clients/logo_ifcam_ca.png" alt="IFCAM Logo" className="img-fluid" />
                  <div className="media-1-content">
                    <h2>IFCAM</h2>
                    <span className="category">Développement SharePoint</span>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4 mb-4 d-flex align-items-center justify-content-center">
                <a href="https://www.bollore-logistics.com/" className="media-1" target="_blank" rel="noopener noreferrer">
                  <img src="images/clients/bollore-logistics.png" alt="Bolloré Logistics Logo" className="img-fluid" />
                  <div className="media-1-content">
                    <h2>Bolloré Logistics</h2>
                    <span className="category">Développement SharePoint</span>
                    <br />
                    <span className="category">Gestion de Projet</span>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4 mb-4 d-flex align-items-center justify-content-center">
                <a href="https://www.globecast.com/" className="media-1" target="_blank" rel="noopener noreferrer">
                  <img src="images/clients/Logo_globecast.png" alt="Globecast Logo" className="img-fluid" />
                  <div className="media-1-content">
                    <h2>Globecast</h2>
                    <span className="category">Développement SharePoint</span>
                    <br />
                    <span className="category">Infrastructure SharePoint</span>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4 mb-4 d-flex align-items-center justify-content-center">
                <a href="https://www.loreal.com/" className="media-1" target="_blank" rel="noopener noreferrer">
                  <img src="images/clients/Loreal.png" alt="L'Oréal Logo" className="img-fluid" />
                  <div className="media-1-content">
                    <h2>L'Oréal</h2>
                    <span className="category">Transformation Digitale</span>
                    <br />
                    <span className="category">Migration vers 365</span>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4 mb-4 d-flex align-items-center justify-content-center">
                <a href="https://www.orange-business.com/" className="media-1" target="_blank" rel="noopener noreferrer">
                  <img src="images/clients/Logo_Orange_Business_Services.png" alt="Orange Business Services Logo" className="img-fluid" />
                  <div className="media-1-content">
                    <h2>Orange Business Services</h2>
                    <span className="category">Développement & Infrastructure SharePoint</span>
                    <br />
                    <span className="category">DevOps Microsoft</span>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4 mb-4 d-flex align-items-center justify-content-center">
                <a href="https://www.sncf.com/" className="media-1" target="_blank" rel="noopener noreferrer">
                  <img src="images/clients/Logo_iDTGV.svg" alt="IDTGV Logo" className="img-fluid" />
                  <div className="media-1-content">
                    <h2>IDTGV</h2>
                    <span className="category">Gestion de Projet</span>
                    <br />
                    <span className="category">Maintenance SharePoint</span>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4 mb-4 align-items-center justify-content-center">
                <a href="https://the-mitchellgroup.com/" className="media-1" target="_blank" rel="noopener noreferrer">
                  <img src="images/clients/logo-svg-tmg.svg" alt="TMG Logo" className="img-fluid" />
                  <div className="media-1-content">
                    <h2>The Mitchell Group.Inc</h2>
                    <span className="category">Virtualisation</span>
                    <br />
                    <span className="category">SharePoint Administration</span>
                  </div>
                </a>
              </div>
            </div>
          </div>

        </section>
        <section className="site-section testimonial-wrap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="text-black h1 site-section-heading text-center">Testimonials</h2>
              </div>
            </div>
          </div>
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav
            items={1}
            dots={true}
            autoplay={true}
            autoplayTimeout={4000}
            autoplayHoverPause={true}
          >
            <div className="testimonial">
              <blockquote className="mb-5">
                <p>&ldquo;Grâce à leur expertise en Microsoft 365, notre transition vers le cloud a été un véritable succès. Je recommande vivement cette équipe.&rdquo;</p>
              </blockquote>
              <figure className="mb-4 d-flex align-items-center justify-content-center">
                <div><img src="images/person_1.jpg" alt="Person 1" className="w-50 img-fluid mb-3" /></div>
                <p>Marie D.</p>
              </figure>
            </div>
            <div className="testimonial">
              <blockquote className="mb-5">
                <p>&ldquo;Leur compétence en SharePoint et Teams a transformé notre manière de travailler en interne. Un travail remarquable !&rdquo;</p>
              </blockquote>
              <figure className="mb-4 d-flex align-items-center justify-content-center">
                <div><img src="images/person_2.jpg" alt="Person 2" className="w-50 img-fluid mb-3" /></div>
                <p>Jean-Luc M.</p>
              </figure>
            </div>
            <div className="testimonial">
              <blockquote className="mb-5">
                <p>&ldquo;Leur gestion de projets techniques est impeccable. Ils ont livré le projet dans les délais et bien au-delà de nos attentes.&rdquo;</p>
              </blockquote>
              <figure className="mb-4 d-flex align-items-center justify-content-center">
                <div><img src="images/person_3.jpg" alt="Person 3" className="w-50 img-fluid mb-3" /></div>
                <p>Guillaume L.</p>
              </figure>
            </div>
            <div className="testimonial">
              <blockquote className="mb-5">
                <p>&ldquo;Ils ont une excellente approche pour résoudre les défis techniques. Nous sommes très satisfaits de leur travail.&rdquo;</p>
              </blockquote>
              <figure className="mb-4 d-flex align-items-center justify-content-center">
                <div><img src="images/person_4.jpg" alt="Person 4" className="w-50 img-fluid mb-3" /></div>
                <p>Antoine G.</p>
              </figure>
            </div>
            <div className="testimonial">
              <blockquote className="mb-5">
                <p>&ldquo;L'équipe a fait un travail exceptionnel en matière de transformation digitale. Leur approche centrée sur l'utilisateur a vraiment fait la différence pour nous.&rdquo;</p>
              </blockquote>
              <figure className="mb-4 d-flex align-items-center justify-content-center">
                <div><img src="images/person_5.jpg" alt="Person 5" className="w-50 img-fluid mb-3" /></div>
                <p>Valérie B.</p>
              </figure>
            </div>
          </OwlCarousel>

        </section>

      </div>
    </div>
  )
}

export default ClientReferences;