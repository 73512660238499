import React, { useEffect, useState } from 'react';

const ServicesMicrosoftSolutions = () => {
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      let elem = document.getElementById(window.location.hash.slice(1));
      if (elem) {
        setTimeout(() => {
          window.scrollTo({
            top: elem.getBoundingClientRect().top + window.pageYOffset - 150, // 100px offset
            behavior: 'smooth'
          });
        }, 0);
      }
    }
  }, []);
  
  return (
    <div className="App">
      <div className="site-wrap">

        <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url('${bgImage}')`, backgroundPosition: "50%" }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">
                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>Solutions et Infrastructures Microsoft</h1>
                    <p className="lead mb-5">Optimisez votre environnement de travail avec nos solutions Microsoft intégrées.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="site-section border-bottom" style={{ paddingBottom: "0px" }}>
          <div className="container">
            <div className="row align-items-stretch">
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" data-aos="fade-up" data-aos-delay="100">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-question_answer"></span></div>
                  <div>
                    <h3>Solutions et Infrastructures Microsoft</h3>
                    <p>En tant que spécialistes des solutions Microsoft, notre expertise s'étend bien au-delà des services de base. De la conception et le déploiement d'infrastructures SharePoint à la mise en œuvre de solutions cloud sur Microsoft Azure, nous avons une expérience éprouvée dans la réalisation de projets d'envergure.</p>
                    <p>Nos clients de renom incluent des entreprises comme Orange et L'Oréal. Notre savoir-faire englobe également des domaines tels que la migration SharePoint, la gouvernance, et les solutions de haute disponibilité. Nous sommes également compétents dans la mise en place de solutions de sécurité et de conformité, ce qui fait de nous un partenaire de choix pour vos besoins en solutions Microsoft.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="site-section border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="200" id="m365">
              <div className="p-3 box-with-humber">
                  <div className="number-behind">01.</div>
                  <h2>Microsoft 365</h2>
                  <p>Notre expertise en Microsoft 365 vous permet de tirer le meilleur parti de cette suite de productivité complète. Nous nous occupons de l'intégration, de la configuration et de la gestion de tous les services, du courrier électronique à la collaboration en équipe.</p>
                  <p>Profitez de fonctionnalités avancées telles que la sécurité renforcée et l'automatisation des flux de travail. Nous vous aidons également à migrer en douceur vers Microsoft 365 depuis d'autres plateformes.</p>
                  <p>Faites de Microsoft 365 le moteur de votre transformation numérique.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="300" id="microsoftazure">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">02.</div>
                  <h2>Microsoft Azure</h2>
                  <p>Azure est plus qu'un simple cloud; c'est une solution complète pour votre entreprise. Nous vous aidons à migrer, à développer et à gérer des applications dans le cloud Azure.</p>
                  <p>Nos services incluent la mise en œuvre de solutions de stockage, de calcul et de réseau. Nous nous assurons que votre infrastructure Azure est à la fois évolutive et sécurisée.</p>
                  <p>Avec notre aide, transformez Azure en un atout stratégique pour votre entreprise.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="400" id="infrastructuresharepoint">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">03.</div>
                  <h2>Infrastructure SharePoint</h2>
                  <p>Spécialisés dans la conception d'infrastructures SharePoint de pointe, nous offrons des solutions qui englobent des fermes de serveurs multiples, la redondance, ainsi que des configurations sur site et dans le cloud.</p>
                  <p>Notre expertise s'étend aux infrastructures hybrides, permettant une transition en douceur entre les environnements locaux et le cloud. Nous utilisons des technologies avancées pour garantir la haute disponibilité, la tolérance aux pannes et l'évolutivité. Que vous cherchiez à déployer une nouvelle ferme SharePoint ou à optimiser une infrastructure existante, nous avons les compétences techniques pour répondre à vos besoins les plus exigeants.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="500" id="adfs-authentification">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">04.</div>
                  <h2>ADFS & Authentification</h2>
                  <p>La sécurité commence par une authentification solide. Notre expertise en ADFS (Active Directory Federation Services) vous permet d'implémenter des solutions d'authentification et d'autorisation robustes.</p>
                  <p>Nous configurons des fédérations sécurisées avec des partenaires et des fournisseurs de cloud, garantissant ainsi que seuls les utilisateurs autorisés ont accès à vos ressources. Notre approche est centrée sur la sécurité, mais sans compromettre la facilité d'utilisation pour les utilisateurs finaux. Avec nos solutions d'authentification, protégez votre entreprise sans sacrifier la productivité.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="600" id="multitenancy">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">05.</div>
                  <h2>Solutions multi-locataires</h2>
                  <p>Avec l'évolution de SharePoint vers des technologies cloud, la prise en charge du multi-mandataire sur site a été retirée. Cependant, nous offrons des solutions innovantes pour maximiser l'utilisation des ressources et simplifier la gestion dans des environnements cloud.</p>
                  <p>Nos solutions sont conçues pour garantir l'isolation et la sécurité des données tout en permettant une gestion centralisée. Pour les clients qui dépendent encore du multi-mandataire dans SharePoint Server 2016, nous proposons des stratégies de migration vers Microsoft 365 ou vers des configurations de ferme non multi-mandataires.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="700" id="migration-m365">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">06.</div>
                  <h2>Migration vers Microsoft 365</h2>
                  <p>La migration vers Microsoft 365 est un processus complexe qui nécessite une planification et une exécution minutieuses. Notre équipe d'experts vous accompagne à chaque étape, assurant une transition en douceur vers cette plateforme de productivité complète.</p>
                  <p>Nous avons une expérience éprouvée dans la migration de sites SharePoint, de boîtes aux lettres et de fichiers vers Microsoft 365. Nos services incluent l'automatisation du processus via des scripts PowerShell et des outils comme ShareGate, ainsi que le reporting via Power BI.</p>
                  <p>Que ce soit pour la migration de vos espaces personnels vers OneDrive ou de vos fichiers PST via un processus d'importation automatisée, nous nous assurons que toutes vos données sont transférées de manière sécurisée et efficace.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="800" id="migration-sharepoint">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">07.</div>
                  <h2>Migration SharePoint</h2>
                  <p>La migration vers SharePoint peut être un défi, mais notre expertise vous assure une transition en douceur. Nous offrons des services complets allant de la planification à l'exécution de la migration.</p>
                  <p>Que vous migriez depuis une version antérieure de SharePoint ou d'une autre plateforme, nous nous assurons que toutes vos données, configurations et workflows sont transférés avec précision. Notre objectif est de minimiser les temps d'arrêt et d'assurer une expérience utilisateur ininterrompue.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="900" id="sqlserver">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">08.</div>
                  <h2>SQL Server & Bases de Données</h2>
                  <p>Les bases de données sont le cœur de toute entreprise moderne. Nous fournissons des solutions de bases de données SQL Server qui sont optimisées pour les performances, la fiabilité et la sécurité.</p>
                  <p>De la conception à la maintenance, nous nous occupons de tout. Nos services incluent la migration de bases de données, l'optimisation des performances et la mise en œuvre de solutions de haute disponibilité. Avec notre expertise, assurez-vous que vos données sont toujours accessibles, sécurisées et performantes.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="1000" id="hautedisponibilite">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">09.</div>
                  <h2>Haute Disponibilité</h2>
                  <p>Dans le monde numérique d'aujourd'hui, la disponibilité est plus importante que jamais. Nos solutions de haute disponibilité garantissent que vos services restent accessibles, minimisant ainsi les temps d'arrêt et la perte de productivité.</p>
                  <p>Nous utilisons des technologies de pointe pour créer des systèmes résilients qui peuvent récupérer rapidement en cas de défaillance. Notre approche couvre à la fois les applications et les infrastructures, offrant ainsi une couche supplémentaire de sécurité. Avec nos solutions, assurez la continuité de votre entreprise en toute confiance.</p></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ServicesMicrosoftSolutions;