import React, { useEffect, useState } from 'react';

const DevOpsSecurityCompliance = () => {
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      let elem = document.getElementById(window.location.hash.slice(1));
      if (elem) {
        setTimeout(() => {
          window.scrollTo({
            top: elem.getBoundingClientRect().top + window.pageYOffset - 150, // 100px offset
            behavior: 'smooth'
          });
        }, 0);
      }
    }
  }, []);

  return (
    <div className="App">
      <div className="site-wrap">
        <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url('${bgImage}')`, backgroundPosition: "50%" }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">
                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>DevOps, Sécurité et Conformité</h1>
                    <p className="lead mb-5">Assurez la continuité et la sécurité de votre entreprise avec nos solutions DevOps et de conformité.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="site-section border-bottom" style={{ paddingBottom: "0px" }}>
          <div className="container">
            <div className="row align-items-stretch">
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" data-aos="fade-up" data-aos-delay="100">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-question_answer"></span></div>
                  <div>
                    <h3>DevOps, Sécurité et Conformité</h3>
                    <p>Nous sommes plus qu'un simple fournisseur de services DevOps; MdiaTech est votre partenaire de choix pour intégrer les principes de DevOps, de sécurité et de conformité dans votre entreprise. Nous utilisons des outils et des services Azure pour automatiser les processus, améliorer la collaboration et assurer une livraison continue de haute qualité.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="site-section border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="200"  id="devops-microsoft">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">01.</div>
                  <h2>DevOps Microsoft</h2>
                  <p>Nous exploitons la puissance des outils et services Azure pour créer une culture DevOps au sein de votre organisation.</p>
                  <p>Notre objectif est de maintenir vos logiciels dans un état constamment déployable, tout en améliorant leur qualité et leur fiabilité.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="300" id="automatisation-powershell">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">02.</div>
                  <h2>Automatisation PowerShell</h2>
                  <p>L'automatisation avec PowerShell est au cœur de nos services.</p>
                  <p>Nous utilisons ce puissant framework pour automatiser des tâches administratives complexes, vous permettant ainsi de vous concentrer sur des initiatives plus stratégiques.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="400" id="continuous-integration">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">03.</div>
                  <h2>Intégration Continue</h2>
                  <p>Notre expertise en intégration continue vous permet de réduire les problèmes d'intégration et d'accélérer les cycles de livraison.</p>
                  <p>Nous utilisons des outils comme Azure DevOps Services pour automatiser les builds et les tests, garantissant ainsi la qualité du code à chaque étape.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="500" id="infrastructure-as-code">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">04.</div>
                  <h2>Infrastructure en tant que code</h2>
                  <p>Nous transformons votre infrastructure en code, permettant des déploiements cohérents et répétables.</p>
                  <p>Avec des outils et des services Azure, nous vous aidons à gérer votre infrastructure de manière fiable et évolutive.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="600" id="security-compliance">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">05.</div>
                  <h2>Sécurité et Compliance</h2>
                  <p>La sécurité et la conformité ne sont pas une réflexion après coup chez Mdiatech.</p>
                  <p>Nous intégrons des pratiques de sécurité dès la phase de développement, et nous utilisons des outils comme Azure Security Center pour une surveillance continue et une protection avancée contre les menaces.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="700" id="change-management">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">06.</div>
                  <h2>Change Management</h2>
                  <p>La gestion du changement est cruciale pour le succès de tout projet DevOps.</p>
                  <p>Nous supervisons la transition en douceur des changements de code du développement à la production, minimisant ainsi les risques et les perturbations.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default DevOpsSecurityCompliance;
