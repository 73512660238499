import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';

import Partners from './components/Partners';
import PartnersEMLServicesDataStrength from './components/PartnersEMLServicesDataStrength';
import PartnersEMLServicesDigitalSolutions from './components/PartnersEMLServicesDigitalSolutions';
import PartnersEMLServicesBusinessSolutions from './components/PartnersEMLServicesBusinessSolutions';

import ServicesMicrosoftSolutions from './components/ServicesMicrosoftSolutions';
import ServicesCollaborationProductivity from './components/ServicesCollaborationProductivity';
import ServicesDevOpsSecurity from './components/ServicesDevOpsSecurity';
import ServicesDevelopmentManagement from './components/ServicesDevelopmentManagement';

import ClientReferences from './components/ClientReferences';
import ContactForm from './components/ContactForm';
import Page404 from './components/Page404';
import Footer from './components/Footer';
//import ScrollToTopControlller from './components/ScrollToTopControlller';
import ScrollToTop from './components/ScrollToTop';
import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'slide',
      once: true
    });
  }, []);

  return (
    <React.Fragment>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services-microsoft-solutions" element={<ServicesMicrosoftSolutions />} />
          <Route path="/services-collaboration-productivity" element={<ServicesCollaborationProductivity />} />
          <Route path="/services-devops-security" element={<ServicesDevOpsSecurity />} />
          <Route path="/services-development-management" element={<ServicesDevelopmentManagement />} />
          <Route path="/client-references" element={<ClientReferences />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/partners-eml-services-data-strength" element={<PartnersEMLServicesDataStrength />} />
          <Route path="/partners-eml-services-digital-solutions" element={<PartnersEMLServicesDigitalSolutions />} />
          <Route path="/partners-eml-services-business-solutions" element={<PartnersEMLServicesBusinessSolutions />} />          
          <Route path="/contact" element={<ContactForm />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <Footer />
        <ScrollToTopButton />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;