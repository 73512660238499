import React, { useEffect, useState } from 'react';
import TypedWords from './TypedWords';

const Home = () => {
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);

  return (
    <div className="App">
      <div className="site-wrap">

        <div className="site-blocks-cover overlay" style={{ backgroundImage: `url('${bgImage}')` }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">

                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>Nous sommes experts en <span className="typed-words"><TypedWords /></span></h1>
                    <p className="lead mb-5">Nous créons de la valeur ajoutée</p>
                    <div><a href="/contact" className="btn btn-primary btn-md">Contactez-nous</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="section ft-feature-1">
          <div className="container">
            <div className="row align-items-stretch">
              <div className="col-12 bg-black w-100 ft-feature-1-content">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="h-100">
                      <img src="images/sales-solutions.png" alt="Solutions" className="img-feature img-fluid" />
                    </div>
                  </div>
                  <div className="col-lg-3 ml-auto">
                    <div className="mb-5">
                      <h3 className="d-flex align-items-center"><span className="icon icon-phonelink mr-2"></span><span>Solutions et Infrastructures Microsoft</span></h3>
                      <p>Spécialiste en solutions Microsoft, nous offrons une expertise complète allant de la conception d'infrastructures SharePoint à la mise en œuvre de solutions cloud sur Microsoft Azure.</p>
                      <p><a href="/services">Découvrir</a></p>
                    </div>

                    <div>
                      <h3 className="d-flex align-items-center"><span className="icon icon-extension mr-2"></span><span>Collaboration et Productivité</span></h3>
                      <p>Optimisez la collaboration et la productivité au sein de votre entreprise grâce à nos solutions sur mesure en communication unifiée et automatisation des flux de travail.</p>
                      <p><a href="/services">Découvrir</a></p>
                    </div>

                  </div>
                  <div className="col-lg-3">
                    <div className="mb-5">
                      <h3 className="d-flex align-items-center"><span className="icon icon-format_paint mr-2"></span><span>DevOps, Sécurité et Conformité</span></h3>
                      <p>Votre partenaire de confiance pour intégrer les meilleures pratiques DevOps, garantir la sécurité de vos systèmes et assurer la conformité réglementaire de vos opérations.</p>
                      <p><a href="/services">Découvrir</a></p>
                    </div>

                    <div>
                      <h3 className="d-flex align-items-center"><span className="icon icon-extension mr-2"></span><span>Développement et Gestion de Projet</span></h3>
                      <p>Expérimentés dans les méthodologies Agile, nous vous accompagnons dans le développement de solutions logicielles robustes et la gestion efficace de vos projets.</p>
                      <p><a href="/services">Découvrir</a></p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>


        <section className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">01.</div>
                  <h2>Nos Principes</h2>
                  <p>Le fondement de nos principes réside dans une collaboration étroite et transparente avec nos clients. Nous croyons que le succès durable ne peut être atteint que lorsque nous travaillons main dans la main avec vous pour comprendre vos besoins spécifiques et créer des solutions adaptées.</p>
                  <p>Nous nous engageons à maintenir des normes éthiques élevées, à être responsables et à fournir un service exceptionnel, car votre succès est notre succès.</p>
                </div>
              </div>


              <div className="col-md-6 col-lg-4">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">02.</div>
                  <h2>Notre Mission</h2>
                  <p>Notre mission transcende le simple conseil en management des nouvelles technologies de l'information et du digital. Nous aspirons à être les catalyseurs de votre transformation numérique, en adoptant une approche collaborative pour créer des solutions sur mesure.</p>
                  <p>Notre objectif est de vous aider à naviguer dans le paysage numérique en constante évolution, en optimisant vos opérations et en débloquant de nouvelles avenues de croissance.</p>
                </div>
              </div>


              <div className="col-md-6 col-lg-4">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">03.</div>
                  <h2>Notre Philosophie</h2>
                  <p>Notre philosophie de travail est fondée sur une approche collaborative avec nos clients. Nous croyons fermement que les meilleures solutions émergent lorsque nous travaillons main dans la main avec vous, en comprenant vos besoins spécifiques, vos défis et vos aspirations.</p>
                  <p>Cette collaboration étroite nous permet de créer des solutions qui sont non seulement innovantes mais aussi parfaitement alignées avec vos objectifs commerciaux.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">04.</div>
                  <h2>Notre Stratégie d'Accompagnement Client</h2>
                  <p>Nous aidons nos clients à se transformer et à s'adapter aux exigences en constante évolution du monde numérique. Nous ne nous contentons pas de fournir des solutions technologiques ; nous les personnalisons pour qu'elles correspondent exactement à ce dont votre entreprise a besoin.</p>
                  <p>Chaque projet est une opportunité pour nous de penser et d'imaginer ensemble des solutions qui peuvent réellement faire une différence dans votre organisation.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">05.</div>
                  <h2>Solutions Personnalisées</h2>
                  <p>Nous nous engageons à offrir des solutions sur mesure qui sont le résultat d'une réflexion conjointe et d'une imagination partagée entre notre équipe et la vôtre.</p>
                  <p>Nous prenons le temps de vous écouter, de comprendre vos besoins et de co-créer des solutions qui apportent une valeur ajoutée réelle à votre entreprise.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* 
        <section className="site-section">
          <div className="container">
            <div className="row mb-5 justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="text-black h1 site-section-heading text-center">Featured Projects</h2>
                <p className="lead">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores, itaque neque, delectus odio iure explicabo.</p>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <a href="#" className="media-1">
                  <img src="images/img_1.jpg" alt="Visual 1" className="img-fluid"/>
                  <div className="media-1-content">
                    <h2>Project Name 1</h2>
                    <span className="category">Web Application</span>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="#" className="media-1">
                  <img src="images/img_2.jpg" alt="Visual 2" className="img-fluid"/>
                  <div className="media-1-content">
                    <h2>Project Name 2</h2>
                    <span className="category">Branding</span>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="#" className="media-1">
                  <img src="images/img_3.jpg" alt="Visual 3" className="img-fluid"/>
                  <div className="media-1-content">
                    <h2>Project Name 3</h2>
                    <span className="category">Website</span>
                  </div>
                </a>
              </div>

              <div className="col-12 text-center mt-5">
                <a href="work.html" className="btn btn-primary btn-md">Show All Works</a>
              </div>

              
            </div>
          </div>
        </section>

        <section className="site-section testimonial-wrap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="text-black h1 site-section-heading text-center">Testimonials</h2>
              </div>
            </div>
          </div>
          <div className="slide-one-item home-slider owl-carousel">
              <div>
                <div className="testimonial">
                  
                  <blockquote className="mb-5">
                    <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                  </blockquote>

                  <figure className="mb-4 d-flex align-items-center justify-content-center">
                    <div><img src="images/person_3.jpg" alt="Person 1" className="w-50 img-fluid mb-3"/></div>
                    <p>John Smith</p>
                  </figure>
                </div>
              </div>
              <div>
                <div className="testimonial">

                  <blockquote className="mb-5">
                    <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                  </blockquote>
                  <figure className="mb-4 d-flex align-items-center justify-content-center">
                    <div><img src="images/person_2.jpg" alt="Person 2" className="w-50 img-fluid mb-3"/></div>
                    <p>Christine Aguilar</p>
                  </figure>
                  
                </div>
              </div>

              <div>
                <div className="testimonial">

                  <blockquote className="mb-5">
                    <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                  </blockquote>
                  <figure className="mb-4 d-flex align-items-center justify-content-center">
                    <div><img src="images/person_4.jpg" alt="Person 4" className="w-50 img-fluid mb-3"/></div>
                    <p>Robert Spears</p>
                  </figure>

                  
                </div>
              </div>

              <div>
                <div className="testimonial">

                  <blockquote className="mb-5">
                    <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
                  </blockquote>
                  <figure className="mb-4 d-flex align-items-center justify-content-center">
                    <div><img src="images/person_5.jpg" alt="Person 5" className="w-50 img-fluid mb-3"/></div>
                    <p>Bruce Rogers</p>
                  </figure>

                </div>
              </div>

            </div>
        </section>

        <section className="site-section bg-light">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="text-black h1 site-section-heading text-center">Blog</h2>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4 mb-lg-4">
                <div className="h-entry">
                  <img src="images/img_1.jpg" alt="Visual 1" className="img-fluid"/>
                  <h2 className="font-size-regular"><a href="#">Create Beautiful Website In Less Than An Hour</a></h2>
                  <div className="meta mb-4">Ham Brook <span className="mx-2">&bullet;</span> Jan 18, 2019<span className="mx-2">&bullet;</span> <a href="#">News</a></div>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus eligendi nobis ea maiores sapiente veritatis reprehenderit suscipit quaerat rerum voluptatibus a eius.</p>
                  <p><a href="#">Continue Reading...</a></p>
                </div> 
              </div>
              <div className="col-md-6 col-lg-4 mb-4 mb-lg-4">
                <div className="h-entry">
                  <img src="images/img_2.jpg" alt="Visual 2" className="img-fluid"/>
                  <h2 className="font-size-regular"><a href="#">Create Beautiful Website In Less Than An Hour</a></h2>
                  <div className="meta mb-4">James Phelps <span className="mx-2">&bullet;</span> Jan 18, 2019<span className="mx-2">&bullet;</span> <a href="#">News</a></div>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus eligendi nobis ea maiores sapiente veritatis reprehenderit suscipit quaerat rerum voluptatibus a eius.</p>
                  <p><a href="#">Continue Reading...</a></p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-4 mb-lg-4">
                <div className="h-entry">
                  <img src="images/img_1.jpg" alt="Visual 1" className="img-fluid"/>
                  <h2 className="font-size-regular"><a href="#">Create Beautiful Website In Less Than An Hour</a></h2>
                  <div className="meta mb-4">James Phelps <span className="mx-2">&bullet;</span> Jan 18, 2019<span className="mx-2">&bullet;</span> <a href="#">News</a></div>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus eligendi nobis ea maiores sapiente veritatis reprehenderit suscipit quaerat rerum voluptatibus a eius.</p>
                  <p><a href="#">Continue Reading...</a></p>
                </div> 
              </div>
              
            </div>
          </div>
        </section>

        <a href="#" className="bg-primary py-5 d-block">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md10"><h2 className="text-white">Hire Us &amp; Let's Get Started</h2></div>
            </div>
          </div>  
        </a>
*/}
      </div>
    </div>
  )
}

export default Home;