import React, { useEffect, useState } from 'react';

const PartnersEMLServicesDataStrength = () => {
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);
  return (
    <div className="App">
      <div className="site-wrap">

        <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url('${bgImage}')`, backgroundPosition: "50%" }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">

              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">

                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>Solutions Data EML</h1>
                    <p className="lead mb-5">Répondre à vos besoins est notre priorité.</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <section className="site-section border-bottom" style={{paddingBottom: "0px"}}>
          <div className="container">
            <div className="row align-items-stretch">
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" data-aos="fade-up" data-aos-delay="200">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-laptop2"></span></div>
                  <div>
                    <h3>Solutions Data</h3>
                    <p>Les technologies et compétences à déployer pour obtenir un maximum de valeur sont multiples et adaptées aux besoins Métiers.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="site-section border-bottom">
          <div className="container">
            <div className="row">

              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="300">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">01.</div>
                  <h2>Business Intelligence</h2>
                  <p>Le Système d’Information Décisionnel est devenu une composante essentielle du Système d’Information de toute entreprise. Le Système d’Information Décisionnel collecte, qualifie, normalise, enrichit, agrège des données brutes en volume toujours croissant pour restituer une information à valeur ajoutée.</p>
                  <p>La Business Intelligence connaît aujourd’hui des évolutions majeures, tant en terme de méthodologie, de mise en œuvre qu’en terme de technologie pour répondre aux nouveaux enjeux autour de la Data (Big Data, Data Prep, BI Agile…).</p>
                  <p>La Business Intelligence a pour objectif principal de permettre la prise de décisions avisées dans le but d'accroître la performance économique de l’entreprise (DW Ventes, …)</p>
                  <p>Ces dernières années, les entreprises ont lancé de multiples initiatives visant à mesurer la performance opérationnelle des fonctions supports (DW Achats, DW RH, …).</p>
                  <p>EML Consulting vous accompagne dans la conception et la mise en œuvre de vos projets BI afin de répondre aux nouveaux enjeux stratégiques data des entreprises.</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="400">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">02.</div>
                  <h2>Big Data &amp; Analytics</h2>
                  <p>Appréhender les données massives, multi-structurées, c’est savoir collecter, décrypter, traiter les mégadonnées ou Big Data pour activer les leviers de croissance et de performance des entreprises, quels que soient leur taille ou leur secteur économique.</p>
                  <p>C’est aussi construire de nouveaux Business Models pour assurer leur pérennité et leur développement.</p>
                  <p>C’est enfin créer des avantages concurrentiels durables, en exploitant, d’une part, les gisements de connaissances issues de l’analyse fine de nouvelles sources de données, et, d’autre part, la capacité d’anticipation, voire de prédiction, construite à partir de cette analyse.</p>

                  <p>Valoriser les données massives en les transformant en connaissances exploitables
                  Augmenter les capacités d’analyse pour une meilleure anticipation et prédiction
                  Améliorer la performance des dispositifs digitaux de nouvelle génération
                  Optimiser et faire évoluer les architectures décisionnelles</p>
                  <ul>
                    <li>Une équipe disposant + de 10 ans d’expérience dans la structuration et valorisation des données</li>
                    <li>Un savoir-faire unique sur le marché réunissant toutes les compétences et une offre à 360° sur le Big Data</li>
                    <li>Un positionnement en totale indépendance des éditeurs et fournisseurs de solutions</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="500">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">03.</div>
                  <h2>Gouvernance des données</h2>
                  <p>La gouvernance des données est au centre de la refonte de vos Systèmes d'Information et notamment au centre de vos projets digitaux (réfèrentiel client unique, réfèrentiel produits multicanaux...) et Big Data (maîtrise de la qualité des données internes et externes, réfèrentiels externes...) et fait donc partie des savoir-faire de EML Consulting depuis plus de 10 ans.</p>
                  <p>Au fil des années, les données sont créées, accumulées, stockées parfois de manière disparate, ce qui ne permet pas d’avoir une bonne visibilité de son patrimoine DATA à un instant voulu.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="600">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">04.</div>
                  <h2>Master Data Management</h2>
                  <p>Les solutions de gestion de données de réfèrence (MDM - Master Data Management), de qualité des données (DQM - Data Quality Management), de dictionnaire de données (BG - Business Glossary) ou de gestion des métadonnées (EMM - Entreprise Metadata Management) permettent d'appuyer et de structurer les politiques de gouvernance de données des entreprises.</p>
                  <p>La valorisation de données, à disposition des organisations, nécessite une évolution dans la gestion des données de réfèrences (produits, clients, fournisseurs...). Cela se traduit par leur mise en qualité et leur gouvernance au sein d’un réfèrentiel unique, pour permettre aux acteurs de piloter leurs organisations avec plus d’efficience. Le partage des données centralisées et qualifiées à l’ensemble des métiers de l’entreprise est l’objectif majeur.</p>
                  <ul>                
                    <li>Conseiller &amp; appliquer le cadrage d’initiative MDM</li>
                    <li>Déployer des solutions MDM</li>              
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="700">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">05.</div>
                  <h2>Data Visualization</h2>
                  <p>L’exploration et la mise en forme des données stratégiques d’une entreprise en font d’elles une discipline à part entière dans l’univers de la data. </p>
                  <p>Depuis plusieurs années, la visualisation de la donnée est au cœur de la stratégie des entreprises. Les nombreuses techniques et solutions de Data Visualisation offrent, ainsi, une fenêtre ouverte, intéractive et attractive, sur l’ensemble des données.</p>
                  <p>La Data Visualisation vous permet de comprendre, de hiérarchiser et de mémoriser des données complexes en un clin d’œil.</p>
                  <p>Notre équipe - composée de consultants passionnés, d’experts visionnaires et de partenaires développant des technologies de pointe - vous accompagne dans la compréhension de l’information d'aujourd’hui et de demain.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="800">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">06.</div>
                  <h2>Data Quality Management</h2>
                  <p>Fournir une donnée fiable, objective et utilisable est un enjeu majeur au sein de toutes les entreprises. EML Consulting accompagne ses clients dans la définition et la mise en œuvre de leurs solutions de gestion de la qualité des données (DQM), des choix technologiques à l’organisation, en passant par la gouvernance.</p>
                  <ul>                
                    <li>Conseiller, cadrer et intégrer les mécaniques de gestion de qualité</li>
                    <li>Construire les projets DQM, déployer les solutions</li>              
                    <li>Mettre en place la stratégie de gouvernance de la qualité des données</li>   
                  </ul>
                </div>
              </div>              
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default PartnersEMLServicesDataStrength;