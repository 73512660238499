import React, { useEffect, useState } from 'react';

const DevelopmentAndProjectManagement = () => {
  const [bgImage, setBgImage] = useState('');

  useEffect(() => {
    const images = [
      './images/hero/hero_1.png',
      './images/hero/hero_2.png',
      './images/hero/hero_3.png',
      './images/hero/hero_4.png'
    ];
    const randomIndex = Math.floor(Math.random() * images.length);
    setBgImage(images[randomIndex]);
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      let elem = document.getElementById(window.location.hash.slice(1));
      if (elem) {
        setTimeout(() => {
          window.scrollTo({
            top: elem.getBoundingClientRect().top + window.pageYOffset - 150, // 100px offset
            behavior: 'smooth'
          });
        }, 0);
      }
    }
  }, []);

  return (
    <div className="App">
      <div className="site-wrap">
      <div className="site-blocks-cover inner-page-cover overlay" style={{ backgroundImage: `url('${bgImage}')`, backgroundPosition: "50%" }} data-aos="fade" data-stellar-background-ratio="0.5">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-md-12" data-aos="fade-up" data-aos-delay="100">
                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>Développement et Gestion de Projet</h1>
                    <p className="lead mb-5">Réalisez vos projets avec efficacité grâce à nos méthodologies de développement et de gestion éprouvées.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="site-section border-bottom" style={{ paddingBottom: "0px" }}>
          <div className="container">
            <div className="row align-items-stretch">
              <div className="col-md-9 col-lg-9 mb-9 mb-lg-9" data-aos="fade-up" data-aos-delay="100">
                <div className="unit-4 d-flex">
                  <div className="unit-4-icon mr-4"><span className="text-primary icon-question_answer"></span></div>
                  <div>
                    <h3>Développement et Gestion de Projet</h3>
                    <p>Notre expertise en développement et gestion de projet est ancrée dans une solide expérience avec les méthodologies Agile, notamment SCRUM et KANBAN.</p>
                    <p>Nous vous accompagnons à chaque étape, du cahier des charges à la livraison, en passant par les phases de test et de déploiement.</p>
                    <p>Que ce soit pour le développement en C# et .NET, ou pour des solutions plus spécifiques comme React et le SharePoint Framework, nous avons les compétences et l'expérience pour mener vos projets à bien.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="site-section border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" id="dev-sharepoint-onprem">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">01.</div>
                  <h2>Solutions SharePoint On-Premise</h2>
                  <p>Nous nous spécialisons dans la conception et le développement d'architectures logicielles SharePoint On-Premise.</p>
                  <p>Notre expertise couvre la création de solutions personnalisées qui s'intègrent parfaitement à votre environnement d'entreprise, tout en offrant une grande flexibilité et une facilité de gestion.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="200" id="spfx-react">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">02.</div>
                  <h2>Développement SharePoint Framework avec React</h2>
                  <p>Notre expertise en développement SharePoint Framework avec React permet de créer des solutions SharePoint modernes et réactives.</p>
                  <p>Nous utilisons React pour développer des composants WebPart personnalisés, offrant ainsi une expérience utilisateur fluide et interactive.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="300" id="dotnet">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">03.</div>
                  <h2>Développement .NET & Asp.Net</h2>
                  <p>Fort d'une expérience solide en développement .NET et Asp.Net, nous offrons des solutions robustes et évolutives qui répondent aux besoins spécifiques de votre entreprise.</p>
                  <p>Nos compétences englobent le développement d'applications web, la création de services web RESTful, ainsi que l'intégration avec des bases de données telles que SQL Server.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="400" id="methodologies-agile">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">04.</div>
                  <h2>Méthodologies Agile</h2>
                  <p>L'adoption de méthodologies agile comme Scrum et Kanban est au cœur de notre approche de gestion de projet.</p>
                  <p>Ces méthodologies nous permettent de rester flexibles pendant le développement, d'ajuster nos plans en fonction des retours du client et de livrer des produits de haute qualité.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-6" data-aos="fade-up" data-aos-delay="500" id="alm">
                <div className="p-3 box-with-humber">
                  <div className="number-behind">05.</div>
                  <h2>Practiques ALM</h2>
                  <p>Nos meilleures pratiques en gestion du cycle de vie des applications (ALM) assurent que chaque étape du projet, de la conception au déploiement, est gérée de manière optimale.</p>
                  <p>Nous utilisons des outils et des processus éprouvés pour garantir la qualité et la durabilité de nos solutions.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default DevelopmentAndProjectManagement;
