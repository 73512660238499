import React from 'react';
import Typed from 'typed.js';

const TypedWords = () => {

  // Create reference to store the DOM element containing the animation
  const el = React.useRef(null);

  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        'Solutions et Infrastructures Microsoft',
        'Microsoft 365',
        'Microsoft Azure',
        'Infrastructure SharePoint',
        'ADFS & Authentification',
        'Solutions multi-locataires',
        'Migration vers Microsoft 365',
        'Migration SharePoint',
        'SQL Server & Bases de Données',
        'Haute Disponibilité',
        //'Collaboration et Productivité',
        'Solutions Collaboratives',
        'Microsoft Teams & Communication Unifiée',
        'Gouvernance',
        'Power Apps & Power Automate',
        'Power BI & Reporting',
        //'DevOps, Sécurité et Conformité',
        'DevOps Microsoft',
        'Automatisation PowerShell',
        'Intégration Continue',
        'Infrastructure en tant que code',
        'Sécurité et Compliance',
        'Change Management',
        //'Développement et Gestion de Projet',
        'Solutions SharePoint On-Premise',
        'Développement SharePoint Framework avec React',
        'Développement .NET/ Asp.Net',
        'Méthodologies Agile',
        'Practiques ALM',
      ],      
      typeSpeed: 50,
    });
    

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);

  return (
    <div className="Typed">
      <span ref={el} />
    </div>
  );
};

export default TypedWords;