//We import the useState Hook from React to keep track of the form values.
import { useState, useEffect } from 'react';

//we create a new function called useContactForm, which takes one parameter, callback. 
//Callback is the function that’s passed into the custom Hook from the component. It gets called whenever the form submits.
const useContactForm = (callback,validateContactForm) => {

  //setting up one state variable and one setter function called values and setValues.
  //const [values, setValues] = useState({});
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
      setIsSubmitting(false);  // Reset isSubmitting to false
    } else if (Object.keys(errors).length > 0) {
      setIsSubmitting(false);  // Reset isSubmitting to false if there are errors
    }
  }, [errors, callback, isSubmitting]);
  

  //we create a function called handleSubmit which takes an event. 
  //It prevents the default action of that event (refreshing the page after the event has been called). 
  //Afterwards, it just calls callback();  
  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validateContactForm(values));
    setIsSubmitting(true);
  };

  //We create a function called handleChange which also takes an event.
  const handleChange = (event) => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));

    // Re-validate the form whenever a change is made
    const newErrors = validateContactForm({ ...values, [event.target.name]: event.target.value });
    setErrors(newErrors);
  };
  
  //We return handleChange, handleSubmit and values from the custom Hook so our component has access to them.
  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  }
};

export default useContactForm;